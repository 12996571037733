import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PartnershipForm from "../components/partnershipForm"

import LazyLoad from "react-lazyload"
import ShapePartner from "../components/shapes/ShapePartner"

const Partner = () => (
  <Layout>
    <SEO title="Program Partnerski" />

    <main className="partner_page">
      <ShapePartner />

      <section className="page_header_wrapper">
        <h1 className="page_header smaller">&#60;Program partnerski/&#62;</h1>
      </section>

      <section className="container-fluid partner_section_description">
        <p className="desc">
          Nasz partner krótko objaśnia potencjalnym klientom z czego wynika
          potrzeba serwisowania, aktualizowania, czy tez zabezpieczania na
          bieżąco stron i sklepów internetowych. Udziela także potencjalnym
          klientom informacji o naszej firmie i uzyskuje od potencjalnego
          klienta zgodę na kontakt ze strony naszego przedstawiciela lub sam
          kieruje go bezpośrednio do skontaktowania się z nami.
        </p>
        <h3>Korzyści</h3>
        <div className="row">
          <div className="col-md-6 col-lg-3 itme">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/ico_partner_benefit_1.svg")}
                alt=""
              />
            </LazyLoad>
            <p>
              Prowizja wypłacana każdego miesiąca przez cały okres trwania
              abonamentu przekazanego klienta
            </p>
          </div>
          <div className="col-md-6 col-lg-3 itme">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/ico_partner_benefit_2.svg")}
                alt=""
              />
            </LazyLoad>
            <p>
              Brak odpowiedzialności i jakichkolwiek obowiązków związanych z
              samą obsługą przekazanego nam klienta
            </p>
          </div>
          <div className="col-md-6 col-lg-3 itme">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/ico_partner_benefit_3.svg")}
                alt=""
              />
            </LazyLoad>
            <p>
              Ludzie (znajomi, partnerzy w biznesie) zadowoleni z rzetelnej
              rekomendacji
            </p>
          </div>
          <div className="col-md-6 col-lg-3 itme">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/ico_partner_benefit_4.svg")}
                alt=""
              />
            </LazyLoad>
            <p>
              Raz w roku możliwość bycia wylosowanym spośród wszystkich naszych
              partnerów do nagrody Premium w postaci specjalnej niespodzianki
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid partner_section_contact">
        <h3>Skontaktuj się z nami</h3>
        <div className="inner">
          <PartnershipForm />
        </div>
      </section>
    </main>
  </Layout>
)

export default Partner
