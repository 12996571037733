import React from "react"
import axios from "axios"
import $ from "jquery"

class PartnershipForm extends React.Component {
  componentDidMount() {
    $(".input_wrapper input, .input_wrapper textarea").focusin(function() {
      $(this)
        .parent()
        .addClass("fill")
    })
    $(".input_wrapper input, .input_wrapper textarea").focusout(function() {
      var inputVal = $(this).val().length
      if (inputVal > 0) {
        $(this)
          .parent()
          .addClass("fill")
      } else {
        $(this)
          .parent()
          .removeClass("fill")
      }
    })
  }

  state = {
    firstname: "",
    email: "",
    message: "",
    checked: false,
    sent: false,
    buttonText: "Wyślij",
  }

  resetForm = () => {
    this.setState({
      firstname: "",
      email: "",
      message: "",
      checked: false,
      buttonText: "Wiadomość wysłana",
    })
  }

  formSubmit = e => {
    e.preventDefault()

    this.setState({
      buttonText: "...wysyłanie",
    })

    let formData = new FormData()

    formData.set("firstname", this.state.firstname)
    formData.set("email", this.state.email)
    formData.set("message", this.state.message)

    axios({
      method: "post",
      url:
        "https://forsecurewp.zenx.pl/wp-json/contact-form-7/v1/contact-forms/74/feedback",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        this.setState({ sent: true }, this.resetForm())
        $(".input_wrapper").removeClass("fill")
      })
      .catch(() => {
        console.log("Błąd wysyłania wiadomości...")
      })
  }

  render() {
    return (
      <form onSubmit={e => this.formSubmit(e)}>
        <div className="row">
          <div className="col-sm-6">
            <div className="input_wrapper">
              <label htmlFor="firstname">Imię</label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                name="firstname"
                autoComplete="off"
                value={this.state.firstname}
                onChange={e => this.setState({ firstname: e.target.value })}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="input_wrapper">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                autoComplete="off"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                required
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="input_wrapper">
              <label htmlFor="message">Treść wiadomości</label>
              <textarea
                type="text"
                className="form-control"
                id="message"
                name="message"
                autoComplete="off"
                value={this.state.message}
                onChange={e => this.setState({ message: e.target.value })}
                rows="9"
              ></textarea>
            </div>
          </div>
          <div className="col-md-9">
            <p className="form_info">
              <span>*</span> Wszystkie pola są obowiązkowe
            </p>
            <p className="form_info">
              Zobowiązujemy się do ochrony i poszanowania Państwa prywatności, a
              Państwa dane osobowe będziemy wykorzystywać wyłącznie do
              administrowania Państwa kontem oraz do dostarczania usług, o które
              Państwo prosili. Co jakiś czas chcielibyśmy się z Państwem również
              skontaktować w sprawie naszych produktów i usług. Jeśli zgadzają
              się Państwo na kontakt z nami prosimy o zaznaczenie poniższej
              zgody.
            </p>
            <p className="form_info">
              Wyrażam zgodę na przechowywanie i przetwarzanie moich danych
              osobowych przez Agencję Interaktywną ADream Sp. z o.o. z siedzibą
              w Krakowie, ul. Chopina 18/3a w celu zgodnym z opisem powyżej.
            </p>
            <label className="checkbox_wrapper">
              <input
                type="checkbox"
                checked={this.state.checked}
                onChange={e => this.setState({ checked: !this.state.checked })}
              />
              <span>
                Oświadczam, że przekazanie danych osobowych jest dobrowolne, i
                jestem świadomy, że w każdym czasie przysługuje mi prawo wglądu
                w ich treść, prawo żądania ich zmiany, oraz wycofania niniejszej
                zgody na ich przetwarzanie.
              </span>
            </label>
            <p className="form_info">
              Z informacjami o przetwarzaniu przez nas danych, jak również o
              prawach osób których dane te dotyczą mogą się Państwo zapoznać w
              naszej{" "}
              <a href="/polityka-prywatnosci" target="_blank">
                Polityce Prywatności
              </a>
              .
            </p>
          </div>
          <div className="col-md-3 text-right">
            <button
              type="submit"
              className="btn_custom light"
              disabled={!this.state.checked}
            >
              &#60;{this.state.buttonText}/&#62;
              <span className="btn-arrows"></span>
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default PartnershipForm
